<template>
    <b-form-group>
        <div class="d-flex justify-content-between">
          <app-label >{{label}}</app-label>
        </div>
          <b-input-group
            class="input-group-merge"
            :class="errors.length? 'is-invalid':null"
          >
            <b-form-input   
              :value="value"
              :type="fieldType"
              :state="errors.length?false:null"
              class="form-control-merge" 
              :placeholder="placeholder"
              @input="setValues($event)"
            />

            <b-input-group-append is-text >
              <feather-icon
                class="cursor-pointer"
                :icon="passwordToggleIcon"
                @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>

</template>
<script>
import {
    BFormInput, BInputGroup, BFormGroup, BInputGroupAppend
} from 'bootstrap-vue'
import AppLabel from '@/components/app-components/AppLabel.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'     
    export default {
        components:{
          BFormInput,
          BFormGroup,
          BInputGroup,
          BInputGroupAppend,
          AppLabel
        },
        props:{
            label:[String],
            placeholder:[String],
            value:[String, Number],
            forgetPasswordLink:[String],
            errors:{
              type:[Array],
              default:()=>[]
            }
        },
        data(){
            return {
                fieldType:'password',
                password:'',
            }
        },
        computed: {
            passwordToggleIcon() {
                return this.fieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
        },
        methods:{
            togglePasswordVisibility(){
                if(this.fieldType=='password'){
                    this.fieldType='text'
                }else{
                    this.fieldType='password'
                }
            },
            setValues(value){
                this.$emit('input', value)
            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
</style>